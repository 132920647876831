<script>
  import Heading from "./Heading.svelte";

  export let role;
</script>

<li class="experience-item">
  <header>
    <div>
      <Heading headingType="h3">{role.title}</Heading>
    </div>
    <p class="duration">{role.start} - {role.end}</p>
  </header>
  <ul class="points-list">
    {#each role.points as point}
      <li class="point">{point}</li>
    {/each}
  </ul>
</li>

<style>
  .experience-item {
    padding: 0 0 0.5rem 0;
  }

  header {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 768px) {
    header {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .duration {
    font-size: 0.75rem;
  }

  .points-list {
    padding-top: 0.5rem;
    padding-left: 1.25rem;
    list-style: circle;
  }
  .point {
    padding: 0.2rem 0;
  }
</style>
