<script>
  import Heading from "./Heading.svelte";

  export let contacts;
</script>

<Heading hightlightFactor="0.9" headingType="h2">Contact</Heading>
<ul class="contacts-list">
  {#each contacts as contact}
    <li class="contact-item">
      <div class="contact-item-icon">
        <img class="icon" alt={contact.name} src={contact.icon} />
      </div>
      <div class="contact-item-content">
        <h4 class="sr-only">{contact.name}</h4>
        <p>{contact.value}</p>
      </div>
    </li>
  {/each}
</ul>

<style>
  .contacts-list {
    padding: 0.5rem 0;
    margin: 0;
    list-style: none;
  }

  .contact-item {
    display: flex;
    padding: 0.1rem;
    align-items: center;
  }

  .contact-item-content {
    margin-left: 0.25rem;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .icon {
    width: 18px;
    height: 18px;
    margin-top: 6px;
  }
</style>
