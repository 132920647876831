<script>
  import About from "./components/About.svelte";
  import Contact from "./components/Contact.svelte";
  import Skills from "./components/Skills.svelte";
  import WorkExperience from "./components/WorkExperience.svelte";
  import Education from "./components/Education.svelte";
  import SideProjects from "./components/SideProjects.svelte";
  import Heading from "./components/Heading.svelte";

  let about = {
    name: "Saurabh Sharma",
    jobTitle: "Senior Software Developer",
    link: "itsjzt.com",
    brief:
      "A seasoned developer with 5 years of hands-on experience in Node and React, specializing in building scalable, high-performance software solutions. Skilled in crafting SaaS platforms that meet the demands of modern businesses. Passionate about fusing emerging tech trends with real-world consumer needs to create innovative, impactful digital experiences.",
  };

  let experiences = [
    {
      company: { name: "Codeword Tech", location: "Gurugram" },
      roles: [
        {
          title: "Senior Software Developer",
          start: "March 2019",
          end: "Present",
          points: [
            "Worked on backend and frontend of Macro campaign, a marketing tool that helped eCommerce stores in doing ads more cost-effectively by optimizing their audiences.",
            "Macro outreach, A tool for e-commerce stores to reach their existing audiences through email newsletters, SMS, and WhatsApp.",
            "Led the macro product team of 3 developers, prioritizing tasks by collaborating with stakeholders, beta users and making sure optimum developer productivity.",
            "Built custom online stores varunbahl.com, mayyurgirotra.com, and rohirajaipur.com to improve their load times, SEO, and added custom features to improve sales.",
            "Built a mobile app for the internal team to keep up with the daily sale of the brands we were managing.",
          ],
        },
        // {
        //   title: "Full Stack Developer",
        //   start: "June 2020",
        //   end: "Mar 2023",
        //   points: [
        //     "Built e-commerce store using modern web technologies for ekaya.in to improve their sales and customer experience. Ekaya saw a 15% YoY increase in sales with the new online store.",
        //     "Worked on 4 Shopify Apps (1) Simple Scroll to top, (2) Slick progress bar, (3) Superbar, and (4) Helpmagent.",
        //     "The technology stack of all Shopify apps includes CRA, React.js, Typescript, Apollo Client on the admin side, Svelte for storefront widget and Nodejs, GraphQL for the backend.",
        //   ],
        // },
        // {
        //   title: "Full Stack Intern",
        //   start: "January 2020",
        //   end: "July 2020",
        //   points: [
        //     "Worked on Grey Outline. Grey outline was an e-commerce site built from scratch using React.js, Next.js, GraphQL, Node.js, Typescript",
        //   ],
        // },
      ],
    },
  ];

  let educations = [
    {
      institution: {
        name: "IGNOU",
        location: "Delhi, India",
      },
      fieldOfStudy: "",
      degree: "Bachelor of Computer Applications",
      start: "2023",
      end: "Present",
    },
    {
      institution: {
        name: "DSEU",
        location: "Delhi, India",
      },
      fieldOfStudy: "Computer Engineering",
      degree: "Polytechnic",
      start: "2016",
      end: "2019",
    },
  ];
  let skills = [
    "Typescript",
    "React JS",
    "Node JS",
    "AWS Lambda & Serverless",
    "Graph QL",
    "Next JS",
    "Svelte JS",
    "HTML & CSS",
    "Prisma & Postgres",
    "React Native",
    "Shopify Apps",
    "Python",
    "DevOps",
  ];

  let sideProjects = [
    {
      title: "Redlang",
      description:
        "Programming very similar to Javascript and python, runs on interpreter built in Typescript. Supports variables, functions and closures. Features a Recursive descent parser.",
      link: "github.com/itsjzt/redlang",
    },
    {
      title: "PlainChat | Whatsapp Support",
      description:
        "Shopify app that lets merchant add a whatsapp customer chat button on their stores for easy customer support. Works well in all themes and have tons on customization options.",
      link: "apps.shopify.com/plainchat-whatsapp-support",
    },
  ];

  let contacts = [
    { name: "Phone", value: "+91 8076-623-211", icon: "/icons/phone.svg" },
    { name: "Email", value: "hey@itsjzt.com", icon: "/icons/mail.svg" },
    {
      name: "Linked In",
      value: "linkedin.com/in/itsjzt",
      icon: "/icons/linkedin.svg",
    },
    {
      name: "Github",
      value: "github.com/itsjzt",
      icon: "/icons/github.svg",
    },
  ];
</script>

<div class="wrapper">
  <header>
    <div>
      <Heading headingType="h1">{about.name}</Heading>
      <Heading headingType="h4">{about.jobTitle}</Heading>
      <div>
        {about.link}
      </div>
    </div>
  </header>
  <div class="layout">
    <main>
      <About {about} />
      <WorkExperience {experiences} />
      <SideProjects {sideProjects} />
    </main>
    <aside>
      <Contact {contacts} />
      <Education {educations} />
      <Skills {skills} />
    </aside>
  </div>
</div>

<style>
  .wrapper {
    width: 100%;
    padding-top: 8px;
    max-width: 980px;
    padding-left: 30px;
    margin: 0 auto;
    background: #fafafa;
    border: solid 1px #eaeaea;
  }

  header {
    padding: 8px;
  }

  .layout {
    display: grid;
  }

  main {
    padding: 8px;
  }

  aside {
    padding: 8px;
  }

  @media screen and (min-width: 768px) {
    .layout {
      grid-template-rows: auto;
      grid-template-columns: 2fr 1fr;
    }

    main,
    aside,
    header {
      padding: 10px;
    }

    aside {
      border-left: solid 1px #ddd;
    }
  }
</style>
