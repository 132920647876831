<script>
  import Heading from "./Heading.svelte";

  export let skills;
</script>

<article class="skills">
  <Heading headingType="h2" hightlightFactor="0.9">Skills</Heading>
  <ul class="skills-list">
    {#each skills as skill}
      <li class="skill">{skill}</li>
    {/each}
  </ul>
</article>

<style>
  .skills {
    padding: 20px 0;
  }

  .skills-list {
    padding: 0.5rem 0 0.5rem 1.25rem;
    margin: 0;
  }
</style>
