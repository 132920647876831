<script>
  export let experiences;

  import ExperienceItem from "./ExperienceItem.svelte";
  import Heading from "./Heading.svelte";
</script>

<article class="work-experiences">
  <Heading headingType="h2">Professional Experience</Heading>

  <ul class="experinces-list">
    {#each experiences as experience}
      <div>
        <div class="company">
          <Heading headingType="h5">
            <img
              alt="company"
              src="/icons/building.svg"
              width="16"
              height="16"
              class="company-icons"
            />
            <span class="company-name">{experience.company.name}</span>
            &nbsp;
            <img
              alt="company"
              src="/icons/location-marker.svg"
              width="16"
              height="16"
              class="company-icons"
            />
            <span class="company-location">{experience.company.location}</span>
          </Heading>
        </div>

        <div class="roles">
          {#each experience.roles as role}
            <ExperienceItem {role} />
          {/each}
        </div>
      </div>
    {/each}
  </ul>
</article>

<style>
  .work-experiences {
    padding: 20px 0;
  }

  .experinces-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .company {
    padding: 16px 0 10px 0;
  }

  .roles {
    padding-left: 20px;
  }

  .company-icons {
    margin-right: 2px;
  }
</style>
