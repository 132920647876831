<script>
  import Heading from "./Heading.svelte";

  export let sideProjects;
</script>

<article class="sideProjects">
  <Heading hightlightFactor="1" headingType="h2">Side Projects</Heading>

  <ul class="sideProjects-list">
    {#each sideProjects as sideProject}
      <li class="item">
        <div>
          <h3 class="title">
            {sideProject.title}
          </h3>
          <p class="description">{sideProject.description}</p>
          <p class="link">{sideProject.link}</p>
        </div>
      </li>
    {/each}
  </ul>
</article>

<style>
  .sideProjects {
    padding: 20px 0;
  }

  .item {
    padding-bottom: 1rem;
  }

  .sideProjects-list {
    padding: 0.5rem 0;
    margin: 0;
    list-style: none;
  }

  .title {
    font-weight: 600;
    font-family: "PT Serif", serif;
    font-size: 1.25rem;
    letter-spacing: 0.5px;
    padding-bottom: 0.25rem;
  }

  .description {
    font-size: 1rem;
  }

  .link {
    font-size: 0.75rem;
    color: rgb(140, 140, 140);
    font-weight: 500;
  }
</style>
